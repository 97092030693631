<div rvSpinner="social-media-loader" [rvShowSpinner]="spinner">
    <div id="mainView" [shown]="!currentView" class="social-media-followers-container scrollable-component-container">
      <div class="attribute-editor-component" >
        <div class="scrollable-component-settings te-scrollable-container">

          @if(authenticateFailed || revokeFailed) {
            <div class="attribute-editor-row">
              <div class="row">
                <div class="col-xs-12">
                  <div class="form-group has-error">
                    <p class="help-block">
                      <span [shown] = "authenticateFailed">Sorry, it seems there's an issue with authentication.</span>
                      <span [shown] = "revokeFailed">We could not revoke your access with {{revokeFailedProviderName}} at this time. We strongly recommend you manually revoke access in your social media account.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          }

          <div class="attribute-editor-row">
            <div class="row form-group">
              <div class="col-xs-12">
                You can get the number of followers from you Facebook Page and any Instagram Account. Click “Add Feed” and select a social media or both.
              </div>
            </div>
          </div>


          <div class="feed-selector-container">
            <div class="attribute-editor-row">
              <div class="row form-group">
                <div class="col-xs-6">
                  <label class="control-label u_margin-sm-top">Social Feeds:</label>
                </div>
              <div class="col-xs-6 text-right">
                  <div class="provider-connected-file-info" [hidden]="feeds.size === FEEDS_COUNT">
                    <button class="add-feed-button" mat-stroked-button [matMenuTriggerFor]="addFeedMenu">
                      Add Feed <mat-icon aria-hidden="true" svgIcon="plus" iconPositionEnd></mat-icon>
                    </button>
                    <mat-menu #addFeedMenu="matMenu" xPosition="before">
                      <button mat-menu-item (click)="addFeed(FeedType.FB_PAGE)" [hidden]="feeds.has(FeedType.FB_PAGE)">
                        <mat-icon aria-hidden="true" svgIcon="facebook"></mat-icon>
                        <span>Facebook Page</span>
                      </button>
                      <button mat-menu-item (click)="addFeed(FeedType.IG_PAGE)" [hidden]="feeds.has(FeedType.IG_PAGE)">
                        <mat-icon aria-hidden="true" svgIcon="instagram"></mat-icon>
                        <span>Instagram</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>
            </div>

            @if(feeds.has(FeedType.FB_PAGE)) {
              <div class="attribute-editor-row">
                <div class="row form-group">
                  <div class="col-xs-12">
                    <div class="provider-connected-container">
                      <div class="provider-connected-file-info">
                        <div class="file-details">
                          <mat-icon aria-hidden="true" svgIcon="facebook" class="icon-left"></mat-icon>
                          <div class="file-text">
                            <span class="file-title">{{feeds.get(FeedType.FB_PAGE).pageName}}</span>
                            <span class="file-item-count">Facebook - Page</span>
                          </div>
                        </div>
                        <button class="btn btn-icon pr-0" mat-icon-button [matMenuTriggerFor]="fbPageOptions">
                          <i class="fa fa-chevron-down file-options-icon"></i>
                        </button>
                        <mat-menu #fbPageOptions="matMenu" xPosition="before">
                          <button mat-menu-item (click)="removeFeed(FeedType.FB_PAGE)">
                            <streamline-icon aria-hidden="true" class="mr-3 streamline-component-icon" name="trash" width="16" height="16"></streamline-icon>
                            <span>Remove Feed</span>
                          </button>
                          @if(feeds.get(FeedType.FB_PAGE).userAccount === userAccount) {
                            <hr class="m-2">
                            <button mat-menu-item (click)="confirmDisconnect(FeedType.FB_PAGE)">
                              <streamline-icon aria-hidden="true" class="mr-3 streamline-component-icon" name="unlink" width="16" height="16"></streamline-icon>
                              <span>Disconnect Account</span>
                            </button>
                          }
                        </mat-menu>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 has-error" [shown] = "authStatusFailed.has(FeedType.FB_PAGE)">
                    <p class="help-block">
                      <span>
                        There is an issue with your authentication. Please click on "Disconnect Account" and then add your feed again.
                      </span>
                    </p>
                  </div>  
                </div>
              </div>
            }

            @if(feeds.has(FeedType.IG_PAGE)) {
              <div class="attribute-editor-row">
                <div class="row form-group">
                  <div class="col-xs-12">
                    <div class="provider-connected-container">
                      <div class="provider-connected-file-info">
                        <div class="file-details">
                          <mat-icon aria-hidden="true" svgIcon="instagram" class="icon-left"></mat-icon>
                          <div class="file-text">
                            <span class="file-title">{{feeds.get(FeedType.IG_PAGE).userDisplayName}}</span>
                            <span class="file-item-count">Instagram</span>
                          </div>
                        </div>
                        <button class="btn btn-icon pr-0" mat-icon-button [matMenuTriggerFor]="igPageOptions">
                          <i class="fa fa-chevron-down file-options-icon"></i>
                        </button>
                        <mat-menu #igPageOptions="matMenu" xPosition="before">
                          <button mat-menu-item (click)="removeFeed(FeedType.IG_PAGE)">
                            <streamline-icon aria-hidden="true" class="mr-3 streamline-component-icon" name="trash" width="16" height="16"></streamline-icon>
                            <span>Remove Feed</span>
                          </button>
                          @if(feeds.get(FeedType.IG_PAGE).userAccount === userAccount) {
                            <hr class="m-2">
                            <button mat-menu-item (click)="confirmDisconnect(FeedType.IG_PAGE)">
                              <streamline-icon aria-hidden="true" class="mr-3 streamline-component-icon" name="unlink" width="16" height="16"></streamline-icon>
                              <span>Disconnect Account</span>
                            </button>
                          }
                        </mat-menu>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 has-error" [shown] = "authStatusFailed.has(FeedType.IG_PAGE)">
                    <p class="help-block">
                      <span>
                        There is an issue with your authentication. Please click on "Disconnect Account" and then add your feed again.
                      </span>
                    </p>
                  </div>  
                </div>
              </div>
            }
          </div>


        </div>
      </div>
    </div>

    <div id="addFeedView" [shown]="currentView === 'add_feed'" class="social-media-posts-container scrollable-component-container">
      <div class="attribute-editor-component">

        @if(selectedFeed?.type === FeedType.FB_PAGE) {
          <div class="attribute-editor-row scrollable-component-settings te-scrollable-container page-body page-body-centered">
            <mat-icon aria-hidden="true" aria-label="Facebook Logo" svgIcon="facebook"></mat-icon>
            <div class="add-feed-name">
              Facebook - Page
            </div>
            <div class="add-feed-title">
              Connect to Facebook to pull the content for this feed?
            </div>
            <div class="add-feed-disclaimer">
              We'll never post anything on Facebook. Rise Vision does not access, store, or view personal information or passwords that you may use to connect your accounts.
            </div>
          </div>

          <div class="attribute-editor-row">
            <button class="btn btn-connect-facebook btn-block d-flex align-items-center justify-content-center" (click)="connect()">
              <mat-icon aria-hidden="true" svgIcon="facebook-inverted" class="icon-left icon-connect"></mat-icon>
              <strong>Connect with Facebook</strong>
            </button>
          </div>
        }
        @else {
          <div class="attribute-editor-row scrollable-component-settings te-scrollable-container page-body page-body-centered">
            <mat-icon aria-hidden="true" aria-label="Instagram Logo" svgIcon="instagram"></mat-icon>
            <div class="add-feed-name">
              Instagram
            </div>
            <div class="add-feed-title">
              Connect to Instagram to pull the content for this feed?
            </div>
            <div class="add-feed-disclaimer">
              We'll never post anything on Instagram. Rise Vision does not access, store, or view personal information or passwords that you may use to connect your accounts.
            </div>
          </div>

          <div class="attribute-editor-row">
            <button class="btn btn-connect-instagram btn-block d-flex align-items-center justify-content-center" (click)="connect()">
              <mat-icon aria-hidden="true" svgIcon="instagram-inverted" class="icon-left icon-connect"></mat-icon>
              <strong>Connect with Instagram</strong>
            </button>
          </div>
        }
      </div>
    </div>

    <div id="selectPageView" [shown]="currentView === 'select_page'" class="social-media-posts-container scrollable-component-container">
      <div class="attribute-editor-component">
        <div class="attribute-editor-row scrollable-component-settings te-scrollable-container page-body">

          @if(pagesApiFailed) {
            <div class="form-group has-error">
              <p class="help-block">
                <span>Something went wrong.</span>
              </p>
            </div>
          }

          <div class="select-page-title" id="select-facebook-page-label">
            Select a Facebook page
          </div>
          <div>
            <mat-radio-group
              aria-labelledby="select-facebook-page-label"
              class="select-page-radio-group"
              [(ngModel)]="selectedPage">
              @for (page of availablePages; track page.id) {
                <mat-radio-button class="select-page-radio-button" [value]="page">{{page.name}}</mat-radio-button>
              }
            </mat-radio-group>
            <div class="text-sm" [shown]="!availablePages?.length && !pagesApiFailed">
              <span>There are no pages in this account.</span>
            </div>
          </div>
        </div>

        <div class="attribute-editor-row">
          <button id="symbolSelectorButton" class="btn btn-primary btn-block" (click)="addSelectedPage()" [disabled]="!selectedPage">
            <strong>Add Selected Page</strong>
          </button>
        </div>

      </div>
    </div>
  </div>
